////
/// ============================================================================
/// All @font-face declarations, if any
/// ============================================================================
////

// =============================================================================
// Font Awesome
// =============================================================================

@font-face {
	font-family: 'FontAwesome';
	src: url('#{$font-path}fontawesome-webfont.woff') format('woff'),
		 url('#{$font-path}fontawesome-webfont.svg#fontawesome-webfont') format('svg');
	font-weight: normal;
	font-style: normal;
}

// -----------------------------------------------------------------------------

//$fa-font-path: "//netdna.bootstrapcdn.com/font-awesome/4.7.0/fonts" !default;
$fa-font-size-base:   14px !default;
$fa-line-height-base: 1 !default;
$fa-css-prefix:       fa !default;

$fa-var-facebook-square:      '\f082';
$fa-var-linkedin-square:      '\f08c';
$fa-var-instagram:            '\f16d';
$fa-var-map-marker:           '\f041';
$fa-var-phone:                '\f095';
$fa-var-external-link-square: '\f14c';
$fa-var-arrow-circle-o-left:  '\f190';
$fa-var-arrow-circle-o-right: '\f18e';
$fa-var-search:               '\f002';

.#{$fa-css-prefix} {
	display: inline-block;
	font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} FontAwesome; // shortening font declaration
	font-size: inherit; // can't have font-size inherit on line above, so need to override
	text-rendering: auto; // optimizelegibility throws things off #1094
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.#{$fa-css-prefix}-lg {
	font-size: (4em / 3);
	line-height: (3em / 4);
	vertical-align: -15%;
}

.#{$fa-css-prefix}-2x { font-size: 2em; }
.#{$fa-css-prefix}-3x { font-size: 3em; }
.#{$fa-css-prefix}-4x { font-size: 4em; }
.#{$fa-css-prefix}-5x { font-size: 5em; }

.#{$fa-css-prefix}-facebook-square:before { content: $fa-var-facebook-square; }
.#{$fa-css-prefix}-linkedin-square:before { content: $fa-var-linkedin-square; }
.#{$fa-css-prefix}-instagram:before { content: $fa-var-instagram; }

.#{$fa-css-prefix}-map-marker:before { content: $fa-var-map-marker; }
.#{$fa-css-prefix}-phone:before { content: $fa-var-phone; }
.#{$fa-css-prefix}-external-link-square:before { content: $fa-var-external-link-square; }

.#{$fa-css-prefix}-arrow-circle-o-right:before { content: $fa-var-arrow-circle-o-right; }
.#{$fa-css-prefix}-arrow-circle-o-left:before { content: $fa-var-arrow-circle-o-left; }

.#{$fa-css-prefix}-search:before { content: $fa-var-search; }
